$(document).ready(function() {

	/* =====  MOBILE MENU TOGGLE  ====== */
	$('.offCanvasToggler').click(function(event) {
		event.preventDefault();
		$('.off-canvas-nav').toggleClass('active');
		$('.fullscreen-overlay').toggleClass('active');
		$('html, body').toggleClass('no-scrolling');
	});

	$('.block-1 .slider .slides').slick({
		infinite: true,
		dots: false,
		arrows: true,
		prevArrow: $('.block-1 .slider .slider-controls .prev-slide'),
		nextArrow: $('.block-1 .slider .slider-controls .next-slide')
	});

	/* =====  Fancybox  ====== */
	$(".fancy_article").fancybox();
	$(".fancy_projects").fancybox();
	$(".fancy_video").fancybox();

	
	/* =====  Fancybox Video hover fix  ====== */
	$('.iframe .inner').hover(function(){
		$('.video-placeholder').css('box-shadow', '0 6px 30px -6px rgba(0, 0, 0, .3)');
		$('.video-placeholder').css('transform', 'scale(1.05)');
	}, function(){
		$('.video-placeholder').css('box-shadow', '');
		$('.video-placeholder').css('transform', '');
	});
});
